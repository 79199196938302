import React, { useRef, useEffect } from "react";

import "./styles.scss";

export const MiniMap = props => {
  const L = window.L;
  const map = useRef();
  const { lat, lng } = props;
  let marker;

  //Setting up Leaflet map
  useEffect(() => {
    if (map.current) return; // if map already exists - stop the function

    map.current = L.map("miniMap", {
      zoomControl: false,
      attributionControl: false,
    }).setView([lat, lng], 18);

    L.tileLayer(`https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`, {
      minZoom: 8,
    }).addTo(map.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    marker = new L.marker([lat, lng]).addTo(map.current);

    map.current.on("move", e => {
      const center = map.current.getCenter();
      marker.setLatLng([center.lat, center.lng]);
      props.updateLocation(center.lat, center.lng);
    });

    props.updateLocation(props.lat, props.lng);
  });

  return (
    <>
      <div className="minimap" id="miniMap"></div>
    </>
  );
};
