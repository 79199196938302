import { useSelector } from "react-redux";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export const Loading = () => {
  const total = 3;

  const pwaReady = useSelector(state => state.pwa.ready);
  const gpsReady = useSelector(state => state.gps.ready);
  const userReady = useSelector(state => state.user.ready);

  const calculateProgress = () => {
    return ((pwaReady + gpsReady + userReady) / total) * 100;
  };

  return (
    <div className="loading-view">
      <CircularProgressbar
        value={calculateProgress}
        className="loader"
        styles={buildStyles({
          pathColor: "white",
          trailColor: "transparent",
        })}
      />
      ;
    </div>
  );
};
