import { ChevronLeft } from "react-feather";

export const BugReport = ({ handleBackAction }) => {
  return (
    <div className="menu-view">
      <div className="back action" onClick={handleBackAction}>
        <ChevronLeft size={12} /> Atpakaļ
      </div>
      <h4>Ziņot par kļūdu</h4>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
        luctus vehicula libero quis dapibus.
      </p>
    </div>
  )
}
