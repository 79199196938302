import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Map } from "components/views/Map";

import { CreatePost } from "components/organisms/CreatePost";
import { MainMenu } from "components/organisms/MainMenu";
import { ViewPOI } from "components/organisms/ViewPOI";
import { ViewPost } from "components/organisms/ViewPost";

import { FloatingButton } from "components/atoms/FloatingButton";

import { analytics } from "controllers/fb";

export const Main = ({ installApp, installGPS }) => {
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();

  const dialog = useSelector(state => state.app.dialog);
  const pwaStatus = useSelector(state => state.pwa.status);
  const gpsStatus = useSelector(state => state.gps.status);
  const user = useSelector(state => state.user);

  const cameraButton = useRef();

  const setDialog = name => {
    dispatch({
      type: "app/setDialog",
      dialog: name,
    });
    if (name) {
      analytics.logEvent("dialog_" + name);
    }
  };

  const fileAdded = event => {
    const addedImages = event.target.files;
    setImages([...images, ...addedImages]);
    setDialog("CREATE-NEW-POST");
  };

  const showCameraUpload = () => {
    analytics.logEvent("camera");
    cameraButton.current.click();
  };

  return (
    <>
      <Map />
      <div className="menus">
        <MainMenu
          visible={dialog === "CONFIGURATION"}
          onClose={() => setDialog(null)}
        />
        <ViewPost
          visible={dialog === "EVENT"}
          onClose={() => setDialog(false)}
        />
        <CreatePost
          visible={dialog === "CREATE-NEW-POST"}
          images={images}
          setImages={setImages}
          onClose={() => setDialog(false)}
          showCameraUpload={showCameraUpload}
        />
        <ViewPOI visible={dialog === "POI"} onClose={() => setDialog(false)} />
      </div>
      <div className="map-actions">
        <div className="container">
          <div className="centerpiece">
            <FloatingButton onClick={showCameraUpload} icon="Camera" />
          </div>
          <div className="right-panel">
            {pwaStatus === "INSTALLABLE" && (
              <FloatingButton
                size="small"
                onClick={installApp}
                icon="Download"
              />
            )}
            {gpsStatus === "INSTALLABLE" && (
              <FloatingButton
                size="small"
                onClick={installGPS}
                icon="Navigation"
              />
            )}
            {gpsStatus === "INSTALLED" && (
              <FloatingButton
                active
                size="small"
                onClick={() =>
                  dispatch({
                    type: "gps/setLock",
                    locked: true,
                  })
                }
                icon="Navigation"
              />
            )}
            <FloatingButton
              size="small"
              onClick={() => setDialog(dialog ? null : "CONFIGURATION")}
              icon="Menu"
            />
          </div>
        </div>
      </div>
      <input
        type="file"
        ref={cameraButton}
        className="hidden"
        multiple
        accept="image/*"
        onChange={e => fileAdded(e)}
      />
    </>
  );
};
