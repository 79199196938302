import { useCallback, useEffect, useLayoutEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import hotkeys from "hotkeys-js";

import { Main } from "components/views/Main";
import { Loading } from "components/views/Loading";

import { auth, analytics } from "controllers/fb";
import { store } from "hooks";

import { reportWebVitals } from "core/reportWebVitals";

import "styles/base.scss";

let pwaPrompt = false;

const App = () => {
  const pwaReady = useSelector(state => state.pwa.ready);
  const gpsReady = useSelector(state => state.gps.ready);
  const userReady = useSelector(state => state.user.ready);

  const dispatch = useDispatch();

  const installGPS = useCallback(() => {
    const error = e => console.log(e);

    const success = pos => {
      dispatch({
        type: "gps/setPos",
        lat: pos.coords.latitude,
        long: pos.coords.longitude,
        accuracy: pos.coords.accuracy,
      });
    };

    const options = {
      enableHighAccuracy: true,
    };

    navigator.geolocation.getCurrentPosition(
      pos => {
        dispatch({
          type: "gps/setState",
          status: "INSTALLED",
        });
        analytics.logEvent("install_gps");
        success(pos);
      },
      error,
      options
    );
    navigator.geolocation.watchPosition(pos => success(pos), error, options);
  }, [dispatch]);

  const installApp = async () => {
    pwaPrompt.prompt();
    const { outcome } = await pwaPrompt.userChoice;
    analytics.logEvent("install_app_button");
    if (outcome === "accepted") {
      dispatch({
        type: "pwa/setState",
        status: "INSTALLED",
      });
      analytics.logEvent("install_app_success");
    }
  };

  // Check PWA status
  useLayoutEffect(() => {
    window.addEventListener("beforeinstallprompt", event => {
      event.preventDefault();
      pwaPrompt = event;

      dispatch({
        type: "pwa/setState",
        status: "INSTALLABLE",
      });
    });
  }, [dispatch]);

  // Check GPS status
  useLayoutEffect(() => {
    if (gpsReady) return;

    if (navigator.permissions && navigator.permissions.query) {
      // not supported by Safari
      navigator.permissions.query({ name: "geolocation" }).then(({ state }) => {
        if (state === "prompt") {
          dispatch({
            type: "gps/setState",
            status: "INSTALLABLE",
          });
        }
        if (state === "granted") {
          installGPS();
        }
      });
    } else {
      dispatch({
        type: "gps/setState",
        status: "INSTALLABLE",
      });
    }
  }, [dispatch, gpsReady, installGPS]);

  // Check user status
  useLayoutEffect(() => {
    auth.onAuthStateChanged(async authorizedUser => {
      if (!authorizedUser) {
        dispatch({
          type: "user/clearData",
        });
      } else {
        const token = await auth.currentUser.getIdToken();
        dispatch({
          type: "user/setData",
          phone: authorizedUser.phoneNumber,
          token,
        });
      }
    });
  }, [dispatch]);

  useEffect(() => {
    reportWebVitals();
    hotkeys("esc", () => {
      dispatch({
        type: "app/setDialog",
        dialog: false,
      });
    });
  }, []);

  const appReady = pwaReady && gpsReady && userReady;

  return appReady ? (
    <Main installApp={installApp} installGPS={installGPS} />
  ) : (
    <Loading />
  );
};

export const AppProvider = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};
