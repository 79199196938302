import { useRef, useEffect, useCallback } from "react";

// import POI from "./POI"
// import Events from "./Events"
import positionIcon from "components/icons/iconPosition";

import { useSelector, useDispatch } from "react-redux";

import { db, storage, analytics } from "controllers/fb";
import location from "controllers/location";
import Data from "controllers/data";

import "./styles.scss";

const viewDefaultZoom = 18;

export const Map = () => {
  const L = window.L;
  const dispatch = useDispatch();
  const map = useRef(null);

  const currentPositionIcon = useRef(null);

  const updateOrCreateCurrentLocationIcon = regps => {
    if (!map.current) return;

    if (!currentPositionIcon.current) {
      const icon = L.divIcon({
        html: positionIcon,
        className: `currentPosIcon`,
        iconSize: [30, 30],
        iconAnchor: [15, 30],
      });

      currentPositionIcon.current = new L.marker([regps.lat, regps.long], {
        icon: icon,
        zIndexOffset: 10000,
      }).addTo(layerOverlay.current);
    } else {
      currentPositionIcon.current.setLatLng([regps.lat, regps.long]);
      if (regps.lockMap) {
        map.current.setView([regps.lat, regps.long], viewDefaultZoom);
      }
    }
  };

  const gps = useSelector(state => {
    if (state.gps.status === "INSTALLED") {
      updateOrCreateCurrentLocationIcon(state.gps);
    }
    return state.gps;
  });

  const unlockMap = useCallback(
    () =>
      dispatch({
        type: "gps/setLock",
        locked: false,
      }),
    [dispatch]
  );

  const layerOverlay = useRef(null);
  const layerPOI = useRef(null);
  const layerEvents = useRef(null);

  const resortLayers = () => {
    // console.log(`resorting layers`)

    layerEvents.current.eachLayer(layer => {});

    // layer.zIndexOffset()
  };

  const adaptMarkersByZoom = () => {
    const zoom = map.current.getZoom();
    const adjustedZoom = Math.pow(zoom, 2.5);
    const size = 0.05;
    layerEvents.current.eachLayer(layer => {
      if (!layer._icon) return;
      const adjustedSize = layer._icon.classList.contains("eventStatus_lost")
        ? size * 1.5
        : size;

      layer._icon.style.width = `${adjustedSize * adjustedZoom}px`;
      layer._icon.style.height = `${adjustedSize * adjustedZoom}px`;
      layer._icon.style.marginLeft = `-${(adjustedSize / 2) * adjustedZoom}px`;
      layer._icon.style.marginTop = `-${(adjustedSize / 2) * adjustedZoom}px`;
    });

    layerPOI.current.eachLayer(layer => {
      if (!layer._icon) return;

      layer._icon.style.display = zoom < 11 ? "none" : "block";
    });
  };

  // Setting up Leaflet map
  useEffect(() => {
    if (map.current) return;

    console.log(`🌍 Setting up Leaflet map`, gps);

    map.current = L.map("petSpotMap", {
      zoomControl: false,
      scrollWheelZoom: "center",
      attributionControl: false,
    }).setView(
      [gps.lat, gps.long],
      gps.status !== "INSTALLED" ? 12 : viewDefaultZoom
    );

    L.tileLayer(`https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`, {
      minZoom: 8,
    }).addTo(map.current);

    layerEvents.current = L.layerGroup().addTo(map.current);
    layerPOI.current = L.layerGroup().addTo(map.current);
    layerOverlay.current = L.layerGroup().addTo(map.current);

    if (gps.status === "INSTALLED") {
      updateOrCreateCurrentLocationIcon(gps);
    }

    map.current.on("dragstart", unlockMap);

    map.current.on("zoomend", e => {
      if (e.target._zoom !== viewDefaultZoom) {
        unlockMap();
      }
      adaptMarkersByZoom();
    });
  });

  useEffect(() => {
    console.log(`🔑 Connecting to database and creating listener`);
    layerEvents.current.eachLayer(layer => map.current.removeLayer(layer));

    const openEvent = event => {
      dispatch({
        type: "app/openEvent",
        event: {
          ...event,
          point: null, // point needs to be cleared
          posted_at: event.posted_at.seconds * 1000,
          published_at: null,
        },
      });
    };

    db.collection("events")
      .where("status", "==", "public")
      .onSnapshot(snapshot => {
        snapshot.docChanges().forEach(async change => {
          if (change.type === "added") {
            const eventId = change.doc.id;
            const event = change.doc.data();
            event.id = eventId;
            console.log(`📄 Event`, event);
            const geo = event.point.geopoint;
            const getThumbnail = await storage
              .ref(`${event.images[0]}_100x100`)
              .getDownloadURL();

            const customIcon = L.divIcon({
              html: `<div class="events events_${event.petStatus}" style="background-image:url('${getThumbnail}');"></div>`,
              iconSize: [50, 50],
              iconAnchor: [25, 25],
              className: `eventIcon eventStatus_${event.petStatus}`,
            });
            L.marker([geo.latitude, geo.longitude], {
              icon: customIcon,
              title: event.comment,
              riseOnHover: true,
            })
              .addTo(layerEvents.current)
              .on("click", () => {
                openEvent(event);
                window.history.pushState({}, null, "/" + eventId);
                analytics.logEvent("open_event", {
                  id: eventId,
                });
              });

            const isCurrentlyOpen =
              location.view === "event" && location.id === eventId;
            if (isCurrentlyOpen) {
              openEvent(event);
              unlockMap();
              map.current.setView(
                [event.point.geopoint.latitude, event.point.geopoint.longitude],
                viewDefaultZoom
              );
            }

            adaptMarkersByZoom();
          }
        });
        resortLayers();
      });
  }, [L, dispatch, unlockMap]);

  // Fetch POIs
  useEffect(() => {
    Data.getPOI().then(POIs => {
      POIs.forEach(POI => {
        const customIcon = L.icon({
          iconUrl: require("./assets/poi.svg"),
          iconSize: [30, 30],
          iconAnchor: [15, 15],
          className: "poiIcon",
        });

        L.marker([POI.lat, POI.long], { icon: customIcon, title: POI.title })
          .addTo(layerPOI.current)
          .on("click", () => {
            dispatch({
              type: "app/openPOI",
              POI,
            });
            window.history.pushState({}, null, "/" + POI.id);
            analytics.logEvent("open_POI", {
              id: POI.id,
            });
          });

        const isCurrentlyOpen =
          location.view === "event" && location.id === POI.id;
        if (isCurrentlyOpen) {
          dispatch({
            type: "app/openPOI",
            POI,
          });
          unlockMap();
          map.current.setView([POI.lat, POI.long], viewDefaultZoom);
        }
      });
    });
  }, [L, dispatch, unlockMap]);

  // Location update interval
  /*useEffect(() => {
        const timer = setInterval((marker, map) => {
            if (!map.current) return;

            target.lat = (Location.lat - target.lat) * 0.1 + target.lat
            target.long = (Location.long - target.long) * 0.1 + target.long

            const dist = L.latLng(Location.lat, Location.long).distanceTo( L.latLng(target.lat, target.long) )
            // if distance between current location & new location is more than 500 meters, then just teleport to new location
            if (dist > 500) {
                target.lat = Location.lat
                target.long = Location.long
            }

            if (lockMap) {
                map.current.setView([target.lat, target.long])
            }

            if(marker) marker.setLatLng([target.lat, target.long])
        }, 1000 / 30, currentPosMarker, map)

        return () => clearInterval(timer, map)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])*/

  return <div className="map-container" id="petSpotMap"></div>;
};
