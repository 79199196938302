import { createSlice } from "@reduxjs/toolkit"

export const pwa = createSlice({    
    name: "pwa",
    initialState: {
        ready: true,
        status: "UNKNOWN", // UNKNOWN, INSTALLABLE, INSTALLED
    },
    reducers: {
        setState: (state, payload) => {
            state.ready = true
            state.status = payload.status
        },
    }
})

export const { setState } = pwa.actions

export const setStatusState = state => state.pwa.status

export default pwa.reducer
