import * as FeatherIcon from "react-feather";

import "./styles.scss";

const iconSizes = {
  medium: 28,
  small: 20,
};

export const FloatingButton = ({
  active = false,
  size = "medium",
  icon = "HelpCircle",
  onClick = () => {},
}) => {
  let fabClass = "fab";
  if (icon) fabClass += ` ${icon.toLowerCase()}`;
  if (size) fabClass += ` ${size}`;
  if (active) fabClass += " active";

  const IconComponent = FeatherIcon[icon];

  return (
    <div onClick={onClick} className={fabClass}>
      <IconComponent
        className={icon.toLowerCase()}
        color="currentColor"
        size={iconSizes[size]}
      />
    </div>
  );
};
