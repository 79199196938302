import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import Moment from "react-moment";
import "moment/locale/lv";
import parsePhoneNumber from "libphonenumber-js";

import { PhoneCall } from "react-feather";

import { SlideoutPanel } from "components/atoms/SlideoutPanel";

import { storage } from "controllers/fb";

import "./styles.scss";

export const ViewPost = ({ visible = false, onClose }) => {
  const [images, setImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const event = useSelector(state => state.app.event);

  useEffect(() => {
    if (!event) return;
    const storageURLs = event.images.map(image =>
      storage.ref(`${image}_800x800`).getDownloadURL()
    );
    Promise.all(storageURLs).then(urls => setImages(urls));
  }, [event]);

  useEffect(() => {
    if (visible) {
      setImages([]);
      setCurrentImage(0);
    }
  }, [visible]);

  // const handleShare = useCallback(async () => {
  //   await navigator.share({
  //     title: `🐱 PetSpot`,
  //     text: event.comment,
  //     url: "/" + event.id,
  //   });
  // }, [event]);

  const phoneNumber = parsePhoneNumber(event?.phone || "", "LV");

  const handleClose = () => {
    onClose();
  };

  const isLost = event.petStatus === "lost";

  let viewClass = "view-post";
  if (isLost) viewClass += " lost";

  return (
    <SlideoutPanel
      className={(isLost && "alert poi") || "poi"}
      onClose={handleClose}
      visible={visible && images.length}>
      <div className={viewClass}>
        <div className="gallery-container">
          <SwipeableViews
            enableMouseEvents
            index={images.length > 1 ? currentImage : 0}
            onChangeIndex={setCurrentImage}
            className="gallery">
            {images.map((image, index) => (
              <img draggable="false" className="item" key={index} src={image} />
            ))}
          </SwipeableViews>
          {images.length > 1 && (
            <div className="pagination">
              {images.map((item, index) => {
                let itemClass = "dot";
                if (index === currentImage) itemClass += " active";
                return <div className={itemClass}></div>;
              })}
            </div>
          )}
        </div>
        <div className="content-container">
          {event.comment && (
            <div className="comment">
              <p>{event.comment}</p>
            </div>
          )}
          {event.posted_at && (
            <div className="datetime">
              <Moment locale="lv" interval={1} fromNow>
                {new Date(event.posted_at)}
              </Moment>
            </div>
          )}
        </div>
        {event.petStatus === "lost" && phoneNumber && (
          <div className="phone-container">
            <a
              rel="noreferrer"
              href={phoneNumber.getURI()}
              className="phone-button">
              <PhoneCall className="icon" color="currentColor" />
              {phoneNumber.formatInternational()}
            </a>
          </div>
        )}
      </div>
    </SlideoutPanel>
  );
};
