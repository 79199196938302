export const Logo = () => {
  return (
    <svg
      width="77"
      height="50"
      viewBox="0 0 77 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_32:144)">
        <path
          d="M8.37786 15.458H6.83206V19.5038C8.51145 19.8664 9.54198 20.3244 9.96183 20.8397L9.61832 23.6832H0.343511L0 20.8397C0.305343 20.458 0.935114 20.0763 1.88931 19.7137V4.02672C0.954198 3.68321 0.324427 3.28244 0 2.84351L0.343511 0H9.44656C12.6336 0.0381679 15 0.629771 16.5458 1.75573C18.1298 2.8626 18.9122 4.82824 18.9122 7.67176C18.9122 12.8626 15.4008 15.458 8.37786 15.458ZM8.89313 3.74046H6.83206V11.6985H9.14122C10.7061 11.6985 11.8511 11.3931 12.6145 10.7634C13.3969 10.1527 13.7786 9.08397 13.7786 7.57634C13.7786 6.04962 13.3588 5.01908 12.5 4.5229C11.6794 4.00763 10.4771 3.74046 8.89313 3.74046Z"
          fill="#FF16A2"
        />
        <path
          d="M27.6336 23.9695C24.8855 23.9695 22.8244 23.2443 21.4122 21.7748C20.0382 20.3054 19.3511 18.187 19.3511 15.3817C19.3511 13.6832 19.5992 12.2328 20.0763 11.0115C20.5534 9.77101 21.2023 8.81681 22.042 8.16796C23.645 6.88933 25.5153 6.24048 27.6336 6.24048C29.771 6.24048 31.3931 6.79391 32.5 7.8817C33.645 8.9504 34.2175 10.897 34.2175 13.7214C34.2175 15.3626 33.3969 16.2023 31.7748 16.2023H24.332C24.4275 17.6527 24.7901 18.6641 25.4389 19.2557C26.1069 19.8473 27.1374 20.1527 28.5687 20.1527C29.3511 20.1527 30.0954 20.0573 30.8015 19.8855C31.5076 19.6947 32.0229 19.5229 32.3473 19.3321L32.8244 19.0649L33.8549 21.813C33.7214 21.9657 33.5114 22.1756 33.2443 22.4237C32.9962 22.6527 32.3282 22.9771 31.2595 23.3779C30.1908 23.7786 28.9885 23.9695 27.6336 23.9695ZM29.8091 13.1489C29.8473 12.8244 29.8855 12.4428 29.8855 11.9848C29.8855 11.5267 29.6946 11.0496 29.332 10.5344C28.9695 10 28.3206 9.75193 27.4046 9.75193C26.5076 9.75193 25.8206 10.0191 25.3435 10.5725C24.8664 11.126 24.542 12.042 24.3893 13.3588L29.8091 13.1489Z"
          fill="#FF16A2"
        />
        <path
          d="M41.7366 2.19464V6.5267H46.6412V9.40838H41.7366V18.0916C41.7366 18.8168 41.8321 19.3702 42.042 19.7137C42.2519 20.0382 42.7481 20.1908 43.5115 20.1908C44.2939 20.1908 45.1336 20.0191 46.0115 19.6756L47.042 22.3473C45.2863 23.2443 43.4733 23.6832 41.6221 23.6832C39.7901 23.6832 38.5496 23.3015 37.8817 22.5191C37.2328 21.7175 36.9275 20.5534 36.9275 19.0458V9.40838H35.3817L35 7.30914C35.3626 6.87021 36.126 6.43128 37.271 6.01143C37.5382 4.14121 38.2061 2.86258 39.2366 2.19464H41.7366Z"
          fill="#FF16A2"
        />
        <path
          d="M11.5649 29.771C10.7824 29.5611 9.98092 29.4656 9.12214 29.4656C8.28245 29.4656 7.59542 29.6756 7.06107 30.1145C6.56489 30.5343 6.29771 31.1259 6.29771 31.9084C6.29771 32.6717 6.48855 33.2633 6.88932 33.7023C7.271 34.1412 8.05344 34.5229 9.19848 34.8282L12.2519 35.7252C13.8931 36.2023 15.2099 36.9656 16.1641 37.9962C17.1565 39.0076 17.6336 40.4962 17.6336 42.4618C17.6336 44.7901 16.7939 46.6412 15.0954 47.9962C13.416 49.3511 11.3359 50.0191 8.85497 50.0191C7.48092 50.0191 6.06871 49.8473 4.59924 49.5038C3.14886 49.1412 1.94657 48.6259 0.992371 47.9962C0.992371 47.8626 0.992371 47.5954 0.992371 47.2137C0.992371 46.8321 1.06871 46.1641 1.24046 45.229C1.39313 44.2939 1.62214 43.5305 1.92749 42.9198H5.19084C5.55344 43.6259 5.78245 44.5801 5.8397 45.7633C6.64123 46.1069 7.57634 46.2786 8.62596 46.2786C9.69466 46.2786 10.5916 46.0305 11.2786 45.5153C11.9657 45.0191 12.3092 44.2939 12.3092 43.3588C12.3092 42.9198 12.271 42.5763 12.2137 42.2901C12.1374 41.9847 11.9275 41.6794 11.5649 41.355C11.2214 41.0114 10.7252 40.7443 10.0573 40.5343L6.48855 39.4847C2.90077 38.4542 1.10687 36.1641 1.10687 32.6145C1.10687 30.5343 1.87023 28.874 3.37787 27.6336C4.8855 26.374 6.85115 25.7443 9.27481 25.7443C11.7176 25.7443 14.1221 26.2977 16.4504 27.3855C16.4504 27.5191 16.4504 27.7862 16.4504 28.1679C16.4504 28.5496 16.3741 29.2175 16.2023 30.1527C16.0496 31.0878 15.8206 31.8511 15.5153 32.4618H12.2519C11.8702 31.9275 11.6412 31.0305 11.5649 29.771Z"
          fill="currentColor"
        />
        <path
          d="M25.9542 41.4886H24.4084V45.5344C26.0878 45.897 27.1183 46.355 27.5382 46.8702L27.1947 49.7137H17.9199L17.5764 46.8702C17.8817 46.4886 18.5115 46.1069 19.4657 45.7443V30.0573C18.5306 29.7137 17.9008 29.3321 17.5764 28.8931L17.9199 26.0496H27.0229C30.2099 26.0878 32.5764 26.6794 34.1222 27.8053C35.7061 28.9122 36.4886 30.8779 36.4886 33.7023C36.4886 38.8741 32.9771 41.4886 25.9542 41.4886ZM26.4504 29.771H24.3893V37.729H26.6985C28.2634 37.729 29.4084 37.4237 30.1718 36.7939C30.9542 36.1832 31.3359 35.1145 31.3359 33.6069C31.3359 32.0802 30.9161 31.0496 30.0573 30.5534C29.2367 30.0382 28.0344 29.771 26.4504 29.771Z"
          fill="currentColor"
        />
        <path
          d="M46.8512 50C43.6069 50 41.1642 49.0649 39.542 47.1756C37.939 45.3053 37.1375 42.271 37.1375 38.0725C37.1375 33.855 38.0153 30.7633 39.7901 28.7595C41.584 26.7366 44.1031 25.7443 47.3855 25.7443C50.6871 25.7443 53.1298 26.6412 54.7329 28.416C56.355 30.2099 57.1756 33.187 57.1756 37.3855C57.1756 41.584 56.2787 44.7137 54.4657 46.8321C52.6336 48.9504 50.0955 50 46.8512 50ZM41.4886 31.6603C40.668 32.8435 40.2291 33.2443 40.2291 36.813C40.2291 39.771 40.4008 42.3282 41.8321 43.8168C43.1489 45.1908 44.5039 45.9924 46.8321 46.2595C48.7978 46.2595 51.0687 46.1832 52.9199 43.8168C54.5993 41.6794 54.8474 39.7519 54.6947 36.813C54.4275 30.9733 52.042 30.1908 47.7291 29.4656C44.5993 28.9313 42.3092 30.458 41.4886 31.6603Z"
          fill="currentColor"
        />
        <path
          d="M76.145 26.0687V30.9733L73.9885 31.2405C73.6832 31.0115 73.3969 30.5344 73.1297 29.7901H69.0076V45.7252C69.9236 46.0878 70.5534 46.4695 70.8969 46.8512L70.5534 49.6947H62.519L62.1755 46.8512C62.4809 46.4695 63.1106 46.0878 64.0648 45.7252V29.7901H59.9427C59.6755 30.5153 59.3893 31.0115 59.0839 31.2405L56.9274 30.9733V26.0687H76.145Z"
          fill="currentColor"
        />
        <path
          d="M51.5019 22.8934L49.3545 26.6558L51.9898 28.1599L54.1372 24.3975L51.5019 22.8934Z"
          fill="currentColor"
        />
        <path
          d="M60.9542 3.24429L65.9733 6.06872C66.3359 6.27864 66.4695 6.73666 66.2596 7.09925L56.7939 23.9313C56.584 24.2939 56.126 24.4275 55.7634 24.2176L50.7443 21.3931C50.3817 21.1832 50.2481 20.7252 50.458 20.3626L59.9237 3.53055C60.1145 3.16796 60.5916 3.03437 60.9542 3.24429Z"
          fill="currentColor"
        />
        <path
          d="M47.1565 44.1984C49.6966 44.1984 51.7557 42.3102 51.7557 39.9809C51.7557 37.6516 49.6966 35.7633 47.1565 35.7633C44.6164 35.7633 42.5573 37.6516 42.5573 39.9809C42.5573 42.3102 44.6164 44.1984 47.1565 44.1984Z"
          fill="currentColor"
        />
        <path
          d="M43.0916 36.0687C43.9769 36.0687 44.6946 35.1801 44.6946 34.084C44.6946 32.9878 43.9769 32.0992 43.0916 32.0992C42.2062 32.0992 41.4885 32.9878 41.4885 34.084C41.4885 35.1801 42.2062 36.0687 43.0916 36.0687Z"
          fill="currentColor"
        />
        <path
          d="M46.3168 34.1031C46.9492 34.1031 47.4619 33.4366 47.4619 32.6145C47.4619 31.7924 46.9492 31.126 46.3168 31.126C45.6844 31.126 45.1718 31.7924 45.1718 32.6145C45.1718 33.4366 45.6844 34.1031 46.3168 34.1031Z"
          fill="currentColor"
        />
        <path
          d="M49.542 34.5992C50.1744 34.5992 50.687 33.9328 50.687 33.1107C50.687 32.2886 50.1744 31.6221 49.542 31.6221C48.9096 31.6221 48.397 32.2886 48.397 33.1107C48.397 33.9328 48.9096 34.5992 49.542 34.5992Z"
          fill="currentColor"
        />
        <path
          d="M52.061 36.5649C52.567 36.5649 52.9771 36.0095 52.9771 35.3244C52.9771 34.6394 52.567 34.084 52.061 34.084C51.5551 34.084 51.145 34.6394 51.145 35.3244C51.145 36.0095 51.5551 36.5649 52.061 36.5649Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_32:144">
          <rect width="76.145" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
