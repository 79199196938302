import { configureStore } from "@reduxjs/toolkit"
import gps from "../hooks/gps"
import pwa from "../hooks/pwa"
import user from "../hooks/user"
import app from "../hooks/app"

export const store = configureStore({
    reducer: {
        gps: gps,
        user: user,
        pwa: pwa,
        app: app,
    }
})

export { gps, pwa, user, app }
