import * as firebase from "firebase/app"
import "firebase/analytics"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/performance"
import { firebaseConfig } from "../constants/firebase"

firebase.initializeApp(firebaseConfig)

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

firebase.auth().onAuthStateChanged(user => {
    if(!user) return;
    console.log(`🤷‍♂️`, user.phoneNumber)
})

export const auth = firebase.auth()

export const storage = firebase.storage()

export const db = firebase.firestore()

export const analytics = firebase.analytics()

export const performance = firebase.performance()
