import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import useOnclickOutside from "react-cool-onclickoutside";

import "./styles.scss";

const triggerDistance = 60;

export const SlideoutPanel = ({
  children = null,
  className = null,
  onClose = () => {},
  visible = false,
}) => {
  const [position, setPosition] = useState(null);
  const [offset, setOffset] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);

  const panelRef = useRef(null);

  useOnclickOutside(onClose, {
    detectIFrame: false,
    disabled: !visible,
    ignoreClass: ["menu"],
    refs: [panelRef],
  });

  useEffect(async () => {
    if (position) {
      const dragPosition = position - contentHeight - offset;

      const transform = `translateY(${Math.max(
        -triggerDistance,
        dragPosition
      )}px)`;

      panelRef.current.style.transform = transform;
      panelRef.current.style.transition = "none";
    }
  }, [contentHeight, position, offset]);

  useLayoutEffect(() => {
    setContentHeight(window.innerHeight - panelRef?.current.offsetHeight || 0);
  }, [panelRef, children]);

  const handleTouchStart = useCallback(
    event => {
      const { touches } = event;
      setOffset(touches[0].pageY - contentHeight);
    },
    [contentHeight]
  );
  const handleTouchMove = useCallback(event => {
    const { touches } = event;
    setPosition(touches[0].pageY);
  }, []);
  const handleTouchEnd = useCallback(() => {
    const dragPosition = position - contentHeight - offset;
    if (dragPosition > triggerDistance) onClose();
    setPosition(null);
    panelRef.current.style = null;
  }, [position, triggerDistance, onClose, contentHeight, offset]);

  let panelClass = "bottom-panel";
  if (visible) panelClass += " visible";
  if (className) panelClass += ` ${className}`;

  return (
    <div ref={panelRef} className={panelClass}>
      <div
        className="drag-area"
        onTouchEnd={handleTouchEnd}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}>
        <div className="drag-handler"></div>
      </div>
      {children}
    </div>
  );
};
