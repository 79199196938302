import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Plus, PlusCircle, PlusSquare } from "react-feather";
import SwipeableViews from "react-swipeable-views";

import { MiniMap } from "components/views/MiniMap";

import { SlideoutPanel } from "components/atoms/SlideoutPanel";

import { API } from "controllers/api";
import { analytics } from "controllers/fb";

import "./styles.scss";

export const CreatePost = ({
  visible = false,
  onClose,
  images,
  setImages,
  showCameraUpload,
}) => {
  const user = useSelector(state => state.user);
  const gps = useSelector(state => state.gps);

  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState({
    coords: { lat: 0, long: 0 },
    comment: "",
    phone: "",
    status: "found",
  });

  useEffect(() => {
    if (user.authorized)
      setModel(state => {
        return { ...state, phone: user.phone };
      });
  }, [user.authorized]);

  const handleUpdateLocation = coords => {
    setModel(state => {
      return { ...state, coords };
    });
  };
  const handleChangeType = type => {
    setModel(state => {
      return { ...state, status: type };
    });
  };

  const handleCancel = () => {
    setImages([]);
    onClose();

    analytics.logEvent("post_canceled");
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const form = new FormData();

      Array.from(images).forEach(image => {
        form.append("image", image);
      });

      form.append("status", model.status);
      form.append("comment", model.comment);
      form.append("lat", model.coords.lat);
      form.append("long", model.coords.long);
      if (model.status === "lost") form.append("phone", model.phone);
      if (user.authorized) form.append("token", user.token);

      API.post("/newPost", form, {
        onUploadProgress: progress => {
          console.warn(progress);
        },
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (error) {
      console.log(error);
      analytics.logEvent("post_error", error);
    } finally {
      setLoading(false);
    }
  };

  let viewClass = "create-post";

  let contentContainerClass = "content-container";
  if (loading) contentContainerClass = " loading";

  const types = [
    { title: "Redzēts", type: "found" },
    { title: "Pazaudēts", type: "lost" },
  ];

  return (
    <SlideoutPanel onClose={handleCancel} visible={visible}>
      <div className={viewClass}>
        <div className={contentContainerClass}>
          <h1>Jauns ieraksts</h1>
          <div className="block">
            <div className="type-selector">
              {types.map(item => {
                let typeClass = "type";
                if (item.type === model.status) typeClass += " selected";

                return (
                  <div
                    onClick={() => handleChangeType(item.type)}
                    className={typeClass}>
                    {item.title}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="block">
            <SwipeableViews
              enableMouseEvents
              className="images"
              style={{ width: "100%" }}
              slideStyle={{ width: 88, height: 88 }}>
              <div onClick={showCameraUpload} className="add-image">
                <PlusCircle size={24} strokeWidth={1.25} />
              </div>
              {images.map(image => {
                return (
                  <img className="item" src={URL.createObjectURL(image)} />
                );
              })}
            </SwipeableViews>
          </div>
          <div className="block">
            <MiniMap
              updateLocation={handleUpdateLocation}
              lat={gps.lat}
              lng={gps.long}
            />
          </div>
          <div className="block">
            <label>Apraksts</label>
            <textarea
              onInput={({ currentTarget: { value } }) => {
                setModel(state => {
                  return { ...state, description: value };
                });
              }}
              value={model.description}
              className="description"></textarea>
          </div>
          <div className="block">
            <div className="submit button" onClick={handleSubmit}>
              Iesūtīt
            </div>
          </div>
        </div>
        {loading && (
          <CircularProgressbar
            value={40}
            className="loader"
            styles={buildStyles({
              pathColor: "white",
              trailColor: "transparent",
            })}
          />
        )}
      </div>
    </SlideoutPanel>
  );
};
