import { createSlice } from "@reduxjs/toolkit"

export const gps = createSlice({
    name: "gps",
    initialState: {
        ready: false,
        lat: 56.9521025,
        long: 24.1002611,
        accuracy: 9001,
        status: "UNKNOWN", // UNKNOWN | INSTALLABLE | INSTALLED
        lockMap: true,
    },
    reducers: {
        setLock: (state, action) => {
            state.lockMap = action.locked
        },
        setState: (state, action) => {
            state.ready = true
            state.status = action.status
            if (action.status === "INSTALLED") {
                state.lockMap = true
            }
        },
        setPos(state, payload) {
            state.lat = payload.lat
            state.long = payload.long
            state.accuracy = payload.accuracy
        }
    }
})

export const { setState } = gps.actions

// export const setStatusState = state => state.gps.status

export default gps.reducer
