import { createSlice } from "@reduxjs/toolkit";

export const app = createSlice({
  name: "app",
  initialState: {
    dialog: false,
    event: false,
    POI: false,
  },
  reducers: {
    setDialog: (state, action) => {
      state.dialog = action.dialog;
    },
    openEvent: (state, action) => {
      if (state.dialog === "CONFIGURATION") {
        state.dialog = false;
        state.event = false;
        state.POI = false;
      }
      state.dialog = "EVENT";
      state.event = false;
      state.event = action.event;
    },
    openPOI: (state, action) => {
      if (state.dialog === "CONFIGURATION") {
        state.dialog = false;
        state.event = false;
        state.POI = false;
      }
      state.dialog = "POI";
      state.POI = false;
      state.POI = action.POI;
    },
    closeEvent: state => {
      state.dialog = false;
      state.event = false;
      state.POI = false;
    },
  },
});

export const { setState } = app.actions;

export default app.reducer;
