import { API_URL } from "../constants/firebase";

class Data {
  poi;
  events;
  myEvents;
  storage; // local window storage

  constructor() {
    this.poi = false;
    this.events = false;
    this.storage = window.localStorage;
    const myEvents = this.storage.getItem("myEvents");
    this.myEvents = myEvents === null ? [] : JSON.parse(myEvents);
    // console.log(this.myEvents)
  }

  async getPOI() {
    if (!this.poi) {
      this.poi = await this.fetchPOI();
    }
    return this.poi;
  }

  async getEvents(lat, long) {
    if (!this.events) {
      this.events = await this.fetchEvents(lat, long);
    }
    return this.events;
  }

  fetchPOI() {
    return fetch(`${API_URL}/fetchPOI`, {
      headers: { "Access-Control-Allow-Origin": "*" },
    }).then(res => {
      return res.json();
    });
  }

  newMyEvent(event) {
    this.myEvents.push(event);
    this.saveMyEvents();
  }

  saveMyEvents() {
    this.storage.setItem("myEvents", JSON.stringify(this.myEvents));
  }

  getMyEvents() {
    return this.myEvents;
  }

  fetchEvents(lat, long) {
    return fetch(`${API_URL}/fetchEvents/${lat}/${long}`).then(res =>
      res.json()
    );
  }

  findPOI(id) {
    return this.poi.find(poi => poi.id === id);
  }

  findEvents(id) {
    return this.events.find(events => events.id === id);
  }
}

export default new Data();
