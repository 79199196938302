import { useEffect, useState } from "react";
import * as firebase from "firebase/app";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import "firebase/auth";

import { SlideoutPanel } from "components/atoms/SlideoutPanel";
import { useSelector, useDispatch } from "react-redux";

import { ChevronLeft, UserCheck, UserX } from "react-feather";

import { auth } from "controllers/fb";

import "./styles.scss";
import { Logo } from "./assets/logo";

import { Tutorial } from "./Tutorial"
import { AboutUs } from "./AboutUs"
import { BugReport } from "./BugReport"

export const MainMenu = ({ visible = false, onClose }) => {
  const [view, setView] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: () => {
        setView(null);
      },
    },
    signInOptions: [
      {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        defaultCountry: "LV",
        whitelistedCountries: ["LV", "+371"],
      },
    ],
  };

  useEffect(() => {
    if (visible) setView(null);
  }, [visible]);

  const handleAuthView = () => {
    setView("AUTHORIZATION");
  };

  const handleBackAction = () => {
    setView(null);
  };

  const handleLogout = () => {
    if (window.confirm("Vai tiešām vēlaties atvienot telefona numuru?")) {
      dispatch({ type: "user/clearData" });
      auth.signOut();
    }
  };

  return (
    <SlideoutPanel onClose={onClose} visible={visible}>
      {!view && (
        <div className="main-menu">
          <div className="logo-container">
            <Logo />
          </div>
          <div className="menu-container">
            <div className="menu">
              <div onClick={() => setView("HOW_TO_SCREEN")} className="item">Kā lietot PetSpot?</div>
              <div onClick={() => setView("ABOUT_US")} className="item">Par mums</div>
              <div onClick={() => setView("BUG_REPORT")} href="mailto:dev@petspot.lv" className="item">Ziņot par kļūdu</div>
            </div>
          </div>
          <div className="auth-container">
            {(user.authorized && (
              <>
                <div className="user">
                  <UserCheck className="icon" />
                  <div className="phone">{user.phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1 $2 $3')}</div>
                </div>
                <div className="action" onClick={handleLogout}>
                  Atvienoties
                </div>
              </>
            )) || (
              <>
                <div className="user">
                  <UserX className="icon" />
                  <div className="phone">Nereģistrēts</div>
                </div>
                <div className="action" onClick={handleAuthView}>
                  Autorizēties
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {view === "AUTHORIZATION" && (
        <div className="authorization">
          <div className="back action" onClick={handleBackAction}>
            <ChevronLeft size={12} /> Atpakaļ
          </div>
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </div>
      )}
      {view === "HOW_TO_SCREEN" && (
        <Tutorial handleBackAction={handleBackAction} />
      )}
      {view === "ABOUT_US" && (
        <AboutUs handleBackAction={handleBackAction} />
      )}
      {view === "BUG_REPORT" && (
        <BugReport handleBackAction={handleBackAction} />
      )}
    </SlideoutPanel>
  );
};
