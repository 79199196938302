export const API_URL = "https://api.petspot.lv" // "http://localhost:3001"

export const firebaseConfig = {
    apiKey: "AIzaSyCc2F6nTvnKslrduMSoq7zAqW_aum39kdk",
    authDomain: "petspot-b97ae.firebaseapp.com",
    databaseURL: "https://petspot-b97ae-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "petspot-b97ae",
    storageBucket: "petspot-b97ae.appspot.com",
    messagingSenderId: "348250131102",
    appId: "1:348250131102:web:a2db872081066501e1498f",
    measurementId: "G-S0499MN9JQ"
}
