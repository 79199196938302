import { ChevronLeft } from "react-feather";

export const Tutorial = ({ handleBackAction }) => {
  return (
    <div className="menu-view">
      <div className="back action" onClick={handleBackAction}>
        <ChevronLeft size={12} /> Atpakaļ
      </div>
      <h4>Kā lietot PetSpot?</h4>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
        luctus vehicula libero quis dapibus.
      </p>
      <p>
        Integer ultricies lacus sed mollis ultrices. Pellentesque a enim
        placerat enim tincidunt pellentesque vitae interdum nunc.
      </p>
      <p>
        Aenean semper a purus in pellentesque. Suspendisse viverra, erat
        quis varius pulvinar, ligula mi ullamcorper arcu, nec egestas metus
        ligula vel diam. Nullam eros nibh, molestie a viverra nec, finibus
        sit amet orci.
      </p>
      <h4>Kā vēl kaut ko?</h4>
      <p>
        Proin eget eros at nisl maximus tincidunt ac maximus purus. Proin
        tortor turpis, gravida ut accumsan quis, eleifend nec felis.{" "}
      </p>
      <img src="https://picsum.photos/400/200" />
      <p>
        Integer ultricies lacus sed mollis ultrices. Pellentesque a enim
        placerat enim tincidunt pellentesque vitae interdum nunc.
      </p>
      <p>
        Aenean semper a purus in pellentesque. Suspendisse viverra, erat
        quis varius pulvinar, ligula mi ullamcorper arcu, nec egestas metus
        ligula vel diam. Nullam eros nibh, molestie a viverra nec, finibus
        sit amet orci.
      </p>
    </div>
  )
}
