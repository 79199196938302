import { createSlice } from "@reduxjs/toolkit"

export const user = createSlice({
    name: "user",
    initialState: {
        ready: false,
        authorized: false,
        phone: null,
        token: null,
    },
    reducers: {
        setData: (state, payload) => {
            state.ready = true
            state.authorized = true            
            state.phone = payload.phone
            state.token = payload.token
        },
        clearData: state => {
            state.ready = true
            state.authorized = false
            state.phone = null
            state.token = null
        }
    }
})

export const { setState } = user.actions

// export const setStatusState = state => state.user.status

export default user.reducer
