import { useCallback, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { useSelector } from "react-redux";

import { SlideoutPanel } from "components/atoms/SlideoutPanel";

import "./styles.scss";
import { Share2 } from "react-feather";

export const ViewPOI = ({ visible = false, onClose }) => {
  const poi = useSelector(state => state.app.POI);
  const [currentPage, setCurrentPage] = useState(0);
  const [galleryIndex, setGalleryIndex] = useState(0);

  const handleShare = useCallback(async () => {
    await navigator.share({
      title: `🐱 PetSpot — ${poi.title}`,
      text: poi.description,
      url: "/" + poi.id,
    });
  }, [poi]);

  const handleChangeIndex = index => {
    setCurrentPage(index);
  };

  const handleChangeGalleryIndex = index => {
    setGalleryIndex(index);
  };

  return (
    <SlideoutPanel onClose={onClose} visible={visible}>
      <div className="poi-view">
        <div className="title">
          <div className="content">
            <h1>{poi.title}</h1>
            <p>{poi.address}</p>
          </div>
          <div className="share-icon" onClick={handleShare}>
            <Share2 color="currentColor" />
          </div>
        </div>
        {/* <div className="meta">
          <div className="timetable swipe-slide">
            <div className="title">Darba laiks</div>
            <div className="row">
              <div className="day">Pirmdiena</div>
              <div className="time">9:00 - 17:00</div>
            </div>
            <div className="row">
              <div className="day">Otrdiena</div>
              <div className="time">9:00 - 17:00</div>
            </div>
            <div className="row">
              <div className="day">Trešdiena</div>
              <div className="time">9:00 - 17:00</div>
            </div>
            <div className="row">
              <div className="day">Ceturtdiena</div>
              <div className="time">9:00 - 17:00</div>
            </div>
            <div className="row">
              <div className="day">Piektdiena</div>
              <div className="time">9:00 - 17:00</div>
            </div>
            <div className="row weekend today">
              <div className="day">Sestdiena</div>
              <div className="time">11:00 - 16:00</div>
            </div>
            <div className="row weekend">
              <div className="day">Svētdiena</div>
              <div className="time">11:00 - 16:00</div>
            </div>
          </div>
          <div className="gallery-container">
            <SwipeableViews
              enableMouseEvents
              className="gallery"
              index={galleryIndex}
              onChangeIndex={handleChangeGalleryIndex}>
              <img
                className="gallery-item swipe-slide"
                src="https://www.dole.lv/wp-content/uploads/2008/11/dino_zoo.jpg"
              />
              <img
                className="gallery-item swipe-slide"
                src="https://www.dole.lv/wp-content/uploads/2008/11/dino_zoo.jpg"
              />
            </SwipeableViews>
            <div className="pagination">
              {[0, 1].map((item, index) => {
                let itemClass = "dot";
                if (index === galleryIndex) itemClass += " active";
                return <div className={itemClass}></div>;
              })}
            </div>
          </div>
        </div> */}
      </div>
    </SlideoutPanel>
  );
};
